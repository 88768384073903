var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("park-info-header"),
      _vm._m(0),
      _vm._m(1),
      _vm.tabindex
        ? _c("div", { staticClass: "tabbox" }, [
            _c("div", { staticClass: "tab-top" }, [
              _c(
                "div",
                {
                  class: _vm.tabindex == true ? "tab-index1" : "tab-index2",
                  on: {
                    click: function($event) {
                      return _vm.changetabindex()
                    }
                  }
                },
                [_vm._v("招租空间")]
              ),
              _c(
                "div",
                {
                  class: _vm.tabindex == false ? "tab-index1" : "tab-index2",
                  on: {
                    click: function($event) {
                      return _vm.changetabindex()
                    }
                  }
                },
                [_vm._v("入驻企业")]
              )
            ]),
            _c(
              "div",
              { staticClass: "showImg" },
              [
                _vm._l(_vm.stepItem.split(",", 10), function(item, index) {
                  return _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: index === _vm.currentIndex,
                        expression: "index === currentIndex"
                      }
                    ],
                    key: index,
                    staticClass: "tab1",
                    attrs: { src: item }
                  })
                }),
                _c(
                  "div",
                  {
                    staticClass: "iconDiv icon-left",
                    on: { click: _vm.clickIconup }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-arrow-left",
                      staticStyle: { color: "#fff", "font-size": "12px" }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "iconDiv icon-right",
                    on: { click: _vm.clickIcondown }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-arrow-right",
                      staticStyle: { color: "#fff", "font-size": "12px" }
                    })
                  ]
                ),
                _c("div", { staticClass: "banner-circle" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.stepItem.split(",", 10), function(item, index) {
                      return _c("li", {
                        key: index,
                        class: index === _vm.currentIndex ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.changeImg(index)
                          }
                        }
                      })
                    }),
                    0
                  )
                ])
              ],
              2
            )
          ])
        : _vm._e(),
      !_vm.tabindex
        ? _c("div", { staticClass: "tabbox" }, [
            _c("div", { staticClass: "tab-top" }, [
              _c(
                "div",
                {
                  class: _vm.tabindex == true ? "tab-index1" : "tab-index2",
                  on: {
                    click: function($event) {
                      return _vm.changetabindex()
                    }
                  }
                },
                [_vm._v("招租空间")]
              ),
              _c(
                "div",
                {
                  class: _vm.tabindex == false ? "tab-index1" : "tab-index2",
                  on: {
                    click: function($event) {
                      return _vm.changetabindex()
                    }
                  }
                },
                [_vm._v("入驻企业")]
              )
            ]),
            _c(
              "div",
              { staticClass: "showImg" },
              [
                _vm._l(_vm.stepItem.split(",", 10), function(item, index) {
                  return _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: index === _vm.currentIndex,
                        expression: "index === currentIndex"
                      }
                    ],
                    key: index,
                    staticClass: "tab1",
                    attrs: { src: item }
                  })
                }),
                _c(
                  "div",
                  {
                    staticClass: "iconDiv icon-left",
                    on: { click: _vm.clickIconup }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-arrow-left",
                      staticStyle: { color: "#fff", "font-size": "12px" }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "iconDiv icon-right",
                    on: { click: _vm.clickIcondown }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-arrow-right",
                      staticStyle: { color: "#fff", "font-size": "12px" }
                    })
                  ]
                ),
                _c("div", { staticClass: "banner-circle" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.stepItem.split(",", 10), function(item, index) {
                      return _c("li", {
                        key: index,
                        class: index === _vm.currentIndex ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.changeImg(index)
                          }
                        }
                      })
                    }),
                    0
                  )
                ])
              ],
              2
            )
          ])
        : _vm._e(),
      _c("park-info-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-bg" }, [
      _c("div", { staticClass: "Settlein-box" }, [
        _c("div", { staticClass: "box-item" }, [
          _c("img", {
            attrs: { src: require("../../assets/img/park/process.png") }
          }),
          _c("div", { staticClass: "settlein-text" }, [_vm._v("入驻流程")])
        ]),
        _c("div", { staticClass: "box-item" }, [
          _c("img", {
            attrs: { src: require("../../assets/img/park/enterprise.png") }
          }),
          _c("div", { staticClass: "settlein-text" }, [_vm._v("入驻企业")])
        ]),
        _c("div", { staticClass: "box-item" }, [
          _c("img", {
            attrs: { src: require("../../assets/img/park/cooperation.png") }
          }),
          _c("div", { staticClass: "settlein-text" }, [_vm._v("合作企业")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "process" }, [
      _c("img", {
        staticClass: "title",
        attrs: { src: require("../../assets/img/park/title4.png") }
      }),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          " 网上产业园，是指依托中国成都人力资源服务产业园网络平台吸引机构线上进驻， 为入驻机构提供品牌传播平台，积极推动企业的发展，构建良好的企业生态环境，鼓励和扶持企业成长， 利用信息化手段最大化实现产业集聚及资源整合，以“集聚产业、培育市场、孵化企业、聚焦人才”为核心功能定位， 着力打造多元化、多层次、专业化的人力资源服务产业链。 "
        )
      ]),
      _c("div", { staticClass: "arrow-box" }, [
        _c("div", { staticClass: "blue-item" }, [_vm._v("一、洽谈入园意向")]),
        _c("img", {
          staticClass: "arrow",
          attrs: { src: require("../../assets/img/park/arrow.png") }
        }),
        _c("div", { staticClass: "blue-item" }, [
          _vm._v("二、提交入园申请材料")
        ]),
        _c("img", {
          staticClass: "arrow",
          attrs: { src: require("../../assets/img/park/arrow.png") }
        }),
        _c("div", { staticClass: "blue-item" }, [
          _vm._v("三、评估入园条件、审批")
        ]),
        _c("img", {
          staticClass: "arrow",
          attrs: { src: require("../../assets/img/park/arrow.png") }
        }),
        _c("div", { staticClass: "blue-item" }, [_vm._v("四、签订入园协议")]),
        _c("img", {
          staticClass: "arrow",
          attrs: { src: require("../../assets/img/park/arrow.png") }
        }),
        _c("div", { staticClass: "blue-item" }, [
          _vm._v("五、缴纳保证金、租金")
        ]),
        _c("img", {
          staticClass: "arrow",
          attrs: { src: require("../../assets/img/park/arrow.png") }
        }),
        _c("div", { staticClass: "blue-item" }, [_vm._v("六、装修方案审批")]),
        _c("img", {
          staticClass: "arrow",
          attrs: { src: require("../../assets/img/park/arrow.png") }
        }),
        _c("div", { staticClass: "blue-item" }, [
          _vm._v("七、企业（项目）进驻园区")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }